/** margins */
.article > *:first-child {
  @apply !mt-0;
}

.article .heading-1,
.article p,
.article ul {
  @apply mb-md;
}

.article .heading-1 {
  @apply mt-xl;
}

.article .heading-2, .heading-3 {
  @apply mt-lg;
  @apply mb-xs;
}

.article li {
  @apply mb-xs;
}

/** text style */
.article .heading-1 {
  @apply text-style-headline3;
}
.article .heading-2 {
  @apply text-style-headline4;
}
.article .heading-3 {
  @apply text-style-headline5;
}

.article p,
.article ul {
  @apply text-style-body2;
}

.article ul {
  @apply pl-xl;
}

.article ul li {
  @apply list-disc list-outside;
}
.article ol li {
  @apply list-decimal list-inside;
}

.article a {
  @apply text-style-subtitle1;
  @apply underline;
  @apply hover:no-underline;
}

/** break */
.article h3,
.article h4 {
  @apply break-after-avoid;
}
