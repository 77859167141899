.popper-tooltip-arrow::before {
    content: "";
    background-color: white;
    position: absolute;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
}

.popper-tooltip[data-popper-placement^="top"] > .popper-tooltip-arrow {
    bottom: 6px;
}

.popper-tooltip[data-popper-placement^="bottom"] > .popper-tooltip-arrow {
    top: -6px;
}

.popper-tooltip[data-popper-placement^="left"] > .popper-tooltip-arrow {
    right: -6px;
}

.popper-tooltip[data-popper-placement^="right"] > .popper-tooltip-arrow {
    left: 6px;
}
