.PhoneInput {
    display: flex;
    flex-direction: column;
}

.PhoneInputCountry {
    display:flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.PhoneInputCountryIcon {
    height: 35px;
    width: 35px;
}

.PhoneInputInput {
    padding: 0.5rem;
    border-width: 1px;
    border-radius: 2px;
}

.PhoneInput>input:focus {
    border-width: 1px;
    border-color: rgb(51 51 51 / 1);
    --tw-shadow-colored: 0 0 0 2px #F7A70A;
    box-shadow: var(--tw-shadow-colored);
}

.PhoneNumberContainer {
    display:flex;
    flex-direction:column;
}

.PhoneNumberTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}

@media (max-width: 1215px) {
    .PhoneNumberContainer{
        grid-column: 1 / -1;
    }

    .PhoneInputCountrySelect{
        width:85%;
    }
}