input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@font-face {
  font-family: "Poppins";
  src: local(Poppins-Light), url(/fonts/Poppins-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Poppins";
  src: local(Poppins-Regular),
    url(/fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Poppins";
  src: local(Poppins-SemiBold),
    url(/fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Poppins";
  src: local(Poppins-Bold), url(/fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

/**
 * Hide Google reCAPTCHA badge.
 * Placing within a tailwind layer does not work.
*/
div.grecaptcha-badge {
  @apply hidden;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply font-default;
  }
}

@layer components {
  @import "./article.css";
  @import "./tooltip.css";
  @import "./contactinformation.css";

  @screen max-tablet-lg {
    /* disable container width on "small" screens */
    .container {
      @apply w-full;
      max-width: unset;
    }
  }

  .line-clamp-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  [data-reach-popover] {
    @apply z-50;
  }

  [data-reach-menu-items]:focus-visible {
    @apply default-focus;
  }

  [data-reach-menu-items]:focus-visible [data-reach-menu-item][data-selected] {
    @apply default-focus;
  }

  .clip-path-circle {
    clip-path: circle(50%);
  }

  .clip-path-top-half {
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
  }

  /* Input state */
  .input-default {
    @apply bg-white text-text-primary border rounded border-grey-120;
  }

  .input-hover {
    @apply border-grey-160;
  }

  .input-active {
    @apply border-grey-160 outline-none shadow-field-focus shadow-yellow-100;
  }

  .input-focus {
    @apply border-grey-160 outline-none shadow-field-focus shadow-yellow-100;
  }

  .input-disabled,
  .input-disabled:checked {
    @apply bg-grey-10;
  }

  .input-error {
    @apply border-red-120;
  }

  .input-success {
    @apply border-green-120;
  }

  /* links */
  .link {
    @apply underline;
    @apply hover:no-underline;
    @apply focus-visible:default-focus;
  }

  /* To be used in text with text-style-body1  */
  .text-style-link1 {
    @apply link;
    @apply text-style-subtitle1;
  }

  /* To be used in text with text-style-body2  */
  .text-style-link2 {
    @apply link;
    @apply text-style-subtitle2;
  }

  /* component focus */
  .default-focus {
    @apply ring-4 outline;
    @apply ring-yellow-100 outline-2 outline-grey-160;
    @apply outline-offset-0;
    @apply touch-screen:outline-none;
    @apply touch-screen:ring-0;
  }

  /* card helpers */
  .card-overlay {
    @apply relative;
    @apply before:block;
    @apply before:absolute before:z-50;
    @apply before:w-full before:h-full;
    @apply before:bg-grey-160/10;
    @apply before:rounded-2;
  }

  .card-shadow {
    @apply shadow-elevation-1;
    @apply hover:shadow-elevation-2;
    @apply active:shadow-elevation-1;
  }

  /* backgroud helpers*/
  /*
     bg-fill-right/bg-fill-left adds an overlay between
     the element and the screen end with same background
     as the element it is applied to.
  */
  .bg-fill-right,
  .bg-fill-left {
    @apply relative;
  }

  .bg-fill-right:after,
  .bg-fill-left:before {
    background-color: inherit;
  }

  .bg-fill-right {
    @apply after:absolute after:h-full after:w-screen;
    @apply after:top-0 after:left-full;
  }

  .bg-fill-left {
    @apply before:absolute before:h-full before:w-screen;
    @apply before:top-0 before:right-full;
  }

  /* scrollbar*/
  .styled-scrollbar::-webkit-scrollbar {
    @apply w-sm;
  }

  .styled-scrollbar::-webkit-scrollbar-thumb {
    @apply rounded-full;
    @apply border-solid border-4 border-[transparent];
    box-shadow: inset 0 0 10px 10px rgb(51 51 51 / 30%); /* = grey-160/30 */
  }

  .styled-scrollbar:hover::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgb(51 51 51 / 90%); /* = grey-160/80 */
  }

  /* object header helpers */
  .object-header-height {
    @apply tablet-lg:min-h-[344px];
    @apply laptop:min-h-[456px];
  }

  .section-after-object-header {
    @apply !pt-2xl;
    @apply tablet-lg:min-h-[calc(100vh-344px-56px)];
    @apply laptop:min-h-[calc(100vh-456px)];
    @apply tall:min-h-0;
  }

  /* section height */
  .fill-screen-height {
    @apply min-h-screen;
    @apply tall:min-h-0;
  }
}
